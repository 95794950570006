import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"

const FeaturePracticeManagement = () => (
  <Layout>
    <Seo
      title="Carebit: Privacy Policy"
      pathname="/privacy-policy"
      description="Learn about our privacy policy and how you can exercise your data rights."
    />
    <div className="u-centerFlex u-textCenter Hero">
      <Container>
        <Row>
          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 10, offset: 1 }}
            style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
          >
            <Badge color="warning" className="u-marginBottom20">
              Privacy & security
            </Badge>
            <h1>Privacy policy</h1>
            <p className="u-whiteTranslucent">
              Carebit was built with patient privacy and security at its heart.
              As patients ourselves, we were frustrated at how hard it was to
              find out who had access to our health records and for how long.
              Our policy is to make data transparent - easily available to those
              who need it (like your doctor), and easy to control and understand
              by you, the patient. This policy spells out how we do that.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
    <div>
      <Container>
        <Row>
          <Col md={{ size: 10, offset: 1 }}>
            <section>
              <Row className="u-centerFlex">
                <Col lg={6} md={12}>
                  <Row>
                    <Heading title="Privacy policy">
                      This is the internet privacy policy for carebit.co (the
                      “Website”). It was last updated on March 18th, 2024.
                    </Heading>
                  </Row>
                  <div className="row">
                    <div className="col-md-12 u-marginTop20">
                      <p>
                        This website is the property of Carebit Health Ltd. We
                        take the privacy of all visitors to this Website very
                        seriously and therefore set out in this privacy and
                        cookies policy our position regarding certain privacy
                        matters and the use of cookies on this Website. This
                        policy covers all data that is shared by a visitor with
                        us whether directly via carebit.co or via email.
                      </p>
                      <p>
                        This policy provides an explanation as to what happens
                        to any personal data that you share with us, or that we
                        collect from you either directly via this Website or via
                        email.
                      </p>
                      <p>
                        Certain businesses are required under the data
                        protection act to have a data controller. For the
                        purpose of the Data Protection Act 2018 our Data
                        Controller is Dominic Eden, who can be contacted via
                        email at{" "}
                        <a
                          className="u-fontBold"
                          href="mailto:support@carebit.co"
                        >
                          support@carebit.co
                        </a>
                        .
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>1. Information we collect</h3>
                      <p>
                        In operating our Website we may collect and process the
                        following data about you:
                      </p>
                      <p>
                        <span className="u-fontBold">1.1</span> Details of your
                        visits to our Website and the resources that you access
                        including, but not limited to, traffic data, location
                        data, web log statistics and other communication data.
                      </p>
                      <p>
                        <span className="u-fontBold">1.2</span> Information that
                        you provide by filling in forms on our Website, such as
                        when you register to receive information such as a
                        newsletter or contact us via the contact us page.
                      </p>
                      <p>
                        <span className="u-fontBold">1.3</span> Information
                        provided to us when you communicate with us for any
                        reason.
                      </p>
                      <p>
                        <span className="u-fontBold">1.4</span> Information
                        provided to us when you explicitly choose to share your
                        data with us from a trusted source. For example, you may
                        choose to connect your Gmail account to Carebit to share
                        your name, email address and email messages with us so
                        you can access your emails inside Carebit for easier and
                        more efficient patient communications. We will only use
                        your data in this scenario to improve functionality and
                        experience of Carebit by providing you with the services
                        you requested, such as email processing and sending.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>2. Use of cookies</h3>
                      <p>
                        On occasion, we may gather information about your
                        computer for our services, and to provide statistical
                        information regarding the use of our Website.
                      </p>
                      <p>
                        Such information will not identify you personally, it is
                        statistical data about our visitors and their use of our
                        Website. This statistical data does not identify any
                        personal details whatsoever. It is used by us to analyse
                        how visitors interact with the Website so that we can
                        continue to develop and improve this Website.
                      </p>
                      <p>
                        We may gather information about your general Internet
                        use by using a cookie file that is downloaded to your
                        computer. Where used, these cookies are downloaded to
                        your computer automatically. This cookie file is stored
                        on the hard drive of your computer as cookies contain
                        information that is transferred to your computer’s hard
                        drive. They help us to improve our Website and the
                        service that we provide to you.
                      </p>
                      <p>
                        All computers have the ability to decline cookies. This
                        can be done by activating the setting on your browser
                        which enables you to decline the cookies. Please note
                        that should you choose to decline cookies, you may be
                        unable to access particular areas of our Website.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>3. Use of your information.</h3>
                      <p>
                        The information that we collect and store relating to
                        you is primarily used to enable us to provide our
                        services to you. In addition, we may use the information
                        for the following purposes:
                      </p>
                      <p>
                        <span className="u-fontBold">3.1</span> To provide you
                        with information requested from us relating to our
                        services and to provide information on other services
                        which we feel may be of interest to you if you have
                        consented to receive such information.
                      </p>
                      <p>
                        <span className="u-fontBold">3.2</span> To meet our
                        contractual commitments in delivery of our services to
                        you.
                      </p>
                      <p>
                        <span className="u-fontBold">3.3</span> To notify you
                        about any changes to our Website, such as improvements
                        or service/product changes, that may affect our service.
                      </p>
                      <p>
                        <span className="u-fontBold">3.4</span> If you are an
                        existing patient, we may contact you with information
                        about services similar to those which you previously
                        used.
                      </p>
                      <p>
                        <span className="u-fontBold">3.6</span> If you are a new
                        customer, we will only contact you when you have
                        provided consent and only by those means you provided
                        consent for.
                      </p>
                      <p>
                        <span className="u-fontBold">3.7</span> If you do not
                        want us to use your data for ourselves you will have the
                        opportunity to withhold your consent to this when you
                        provide your details to us on the form on which we
                        collect your data.
                      </p>

                      <p>
                        <span className="u-fontBold">3.8</span> We do not use
                        your data for any other purposes than those stated
                        above.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>4. Storing your personal data</h3>
                      <p>
                        <span className="u-fontBold">4.1</span> Carebit stores
                        your data on behalf of a Partner, such as a private
                        medical clinic, which has enrolled you as a patient.
                        This data includes personal data (e.g. date of birth and
                        address), notes, test results and letters of
                        correspondence. We use encrypted SSL connections
                        throughout Carebit, so whenever your data is entered
                        into or retrieved from Carebit, it is only over an
                        encrypted connection. Your data is also stored in an
                        encrypted database accessible over an encrypted
                        connection with extremely limited access permissions. We
                        will retain your personal information for the length of
                        time needed to fulfill the services delivered by our
                        Partners, unless a longer retention period is required
                        or permitted by law. For more information on the privacy
                        and security processes of the Organization that has
                        enrolled you, please contact them directly.
                      </p>
                      <p>
                        <span className="u-fontBold">4.2</span> While we use
                        bank-grade SSL encryption on our website and throughout
                        our infrastructure to encrypt all communications between
                        your device and our servers, the transmission of
                        information via the internet is not completely secure
                        and therefore we cannot guarantee the security of data
                        sent to us electronically and the transmission of such
                        data is entirely at your own risk. Where we have given
                        you (or where you have chosen) a password so that you
                        can access certain areas of our site or service, you are
                        responsible for keeping this password confidential.
                      </p>
                    </div>
                  </div>

                  <div id="gdpr" className="row">
                    <div className="col-md-12">
                      <h3>5. GDPR compliance summary</h3>
                      <p>
                        We are committed to ensuring the very highest levels of
                        data protection for our Partners, and for the patients
                        belonging to our Partners who have entrusted them with
                        their data. As such, we employ highly-skilled IT
                        professionals to help us achieve this. We use bank-grade
                        encryption, private IP routing, IP whitelisting, VPNs
                        and other best-practices extensively throughout our
                        business.
                      </p>
                      <p>
                        Furthermore, the GDPR regulations require us to explain
                        why we collect patient data and what it is used for.
                        Below is a summary of the key points you need to know
                        before you send your data to us for processing and
                        storage.
                      </p>
                      <ul>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Identity and contact details of the controller and
                              the data protection officer
                            </span>
                            : Please email Dominic Eden (
                            <a
                              className="u-fontBold"
                              href="mailto:info@carebit.co"
                            >
                              info@carebit.co
                            </a>
                            ).
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Purpose of the processing and the legal basis for
                              the processing
                            </span>
                            : Our lawful basis is one of contract. We and our
                            Partners need to store and process your personal
                            data (for example, your date of birth, name, and
                            test results) in order to fulfil our contractual
                            obligations to you in delivering services that you
                            have requested and paid our Partners to perform. We
                            cannot fulfil our contractual obligations to them,
                            and they cannot fulfil their obligations to you
                            without being able to store and process your
                            personal data to understand your medical needs.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Special categories of personal data
                            </span>
                            : on behalf of Partners that use Carebit, we collect
                            and store data relating to patients’ racial or
                            ethnic origin and health. Processing of this data is
                            necessary for the purposes of carrying out our
                            contractual obligations to deliver medical services:
                            racial, health and sexual data is required in order
                            for our Partners to ensure the highest standards of
                            quality and safety of health care.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Any recipient or categories of recipients of the
                              personal data
                            </span>
                            : only Partners can access the data of their
                            patients. Other Partners cannot access your data
                            without your consent or at the request of your
                            doctor for medical reasons.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Details of transfers to third country and
                              safeguards
                            </span>
                            : all data is retained in encrypted form on UK
                            servers. No transfers are made to third parties or
                            to third countries.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Retention period or criteria used to determine the
                              retention period
                            </span>
                            : in accordance with UK law, we keep patient data
                            for 7 years from the date of last appointment in
                            order to be able to provide followup appointments.
                            Additionally, if you choose to transfer to another
                            healthcare provider, our retention period allows us
                            to (with your consent) transfer our patient data
                            (including medical details of services we have
                            performed) to them.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              The existence of each data subject’s rights
                            </span>
                            : you have specified rights under the GDPR
                            legislation. These are:
                          </p>
                          <ul>
                            <li>
                              a right of access to a copy of the information
                              comprised in your personal data;
                            </li>
                            <li>
                              a right to object to processing that is likely to
                              cause or is causing damage or distress;
                            </li>
                            <li>
                              a right to prevent processing for direct
                              marketing;
                            </li>
                            <li>
                              a right to object to decisions being taken by
                              automated means;
                            </li>
                            <li>
                              a right in certain circumstances to have
                              inaccurate personal data rectified, blocked,
                              erased or destroyed; and
                            </li>
                            <li>
                              a right to claim compensation for damages caused
                              by a breach of the Act.
                            </li>
                          </ul>
                          <p>
                            Please contact us to exercise any of these rights.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              The right to withdraw consent at any time
                            </span>
                            : if our Partners have not provided medical services
                            (and so are not required to maintain medical records
                            for 7 years from the last date of service), you may
                            withdraw consent to us storing your information.
                            They will delete your information and notify you of
                            this. Please be aware that our Partners may no
                            longer be able to provide medical services if this
                            consent is withdrawn, and will require you to
                            provide consent again if you require medical
                            services.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              The right to lodge a complaint with a supervisory
                              authority
                            </span>
                            : you have the right to complain to the{" "}
                            <a
                              className="u-fontBold"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://ico.org.uk/"
                            >
                              ICO
                            </a>
                            .
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              Whether the provision of personal data is part of
                              a statutory or contractual requirement or
                              obligation, and the possible consequences of
                              failing to provide the personal data
                            </span>
                            : Our Partners need to store and process your
                            personal data (for example, your date of birth,
                            name, and test results) in order to fulfil their
                            contractual obligations to you in delivering a
                            service that you have requested and paid them to
                            perform (e.g. a consultation or surgery). They
                            cannot fulfil their contractual obligations to you
                            without being able to store and process your
                            personal data to understand your medical needs. The
                            consequences of failing to provide the personal data
                            are that our Partners cannot treat you as a patient.
                          </p>
                        </li>
                        <li>
                          <p>
                            <span className="u-fontBold">
                              The existence of automated decision making,
                              including profiling and information about how
                              decisions are made, the significance and the
                              consequences
                            </span>
                            : Carebit does not currently undertake automated
                            decision making, but you should refer to the privacy
                            policies of our Partners for their specific policy.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>6. Disclosing your information</h3>
                      <p>
                        <span className="u-fontBold">6.1</span> Where
                        applicable, we may disclose your personal information to
                        any member of our group. This includes, where
                        applicable, our subsidiaries, our holding company and
                        its other subsidiaries.
                      </p>
                      <p>
                        <span className="u-fontBold">6.2</span> We may also
                        disclose your personal information to third parties:
                      </p>
                      <p>
                        <span className="u-fontBold">6.2.1</span> Where we sell
                        any or all of our business and/or our assets to a third
                        party.
                      </p>
                      <p>
                        <span className="u-fontBold">6.2.2</span> Where we are
                        legally required to disclose your information.
                      </p>
                      <p>
                        <span className="u-fontBold">6.2.2</span> To assist
                        fraud protection and minimise credit risk.
                      </p>

                      <p>
                        <span className="u-fontBold">6.2.3</span> We do not sell
                        your data to third parties, but we may share it with our
                        partners who help us provide our services. For example,
                        third party providers of IT services that Carebit relies
                        on to store user data and operate our service reliably.
                      </p>

                      <p>
                        <span className="u-fontBold">6.2.4</span> We do not
                        transfer or disclose your information to third parties
                        for purposes other than the ones provided. Strict
                        security procedures are in place to protect the
                        confidentiality of your data, including the mandatory
                        use of SSL encryption and firewalls.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>7. Third party links</h3>
                      <p>
                        You will find links to third party websites on our
                        Website. These websites should have their own privacy
                        policies which you should check. We do not accept any
                        responsibility or liability for their policies
                        whatsoever as we have no control over them.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>8. Access to information</h3>
                      <p>
                        The Data Protection Act 2018 gives you the right to
                        access the information that we hold about you. Should
                        you wish to receive details that we hold about you
                        please contact us using the contact details below.
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <h3>9. Contacting us</h3>
                      <p>
                        We welcome any queries, comments or requests you may
                        have regarding this policy. Please do not hesitate to
                        contact us at{" "}
                        <a className="u-fontBold" href="mailto:info@carebit.co">
                          info@carebit.co
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default FeaturePracticeManagement
