import React from "react"
import { Col } from "reactstrap"

const Heading = ({ title, children }) => (
  <Col md={12}>
    <h4 className="subtext u-darkGreen">{title}</h4>
    <h5 className="u-marginBottom20">{children}</h5>
  </Col>
)

export default Heading
